<template>
  <div class="tipsBox">
    <img class="pageLogo" src="@/assets/images/火星logo.png" alt="">
    <h1>资源管理</h1>
    <p class="tips">
      想要了解 Translator、Translation Samples、Case Study、Voice Sample 等信息， 或者需要通过 CO ID 查项目，烦请移步 
      <a target="_blank" href="https://www.marstranslation.com/client/list-team-members">Mars Translation</a> ，使用您的火星账户登录，查阅
    </p>
  </div>
</template>

<style lang="less" scoped>
h1{

  position: absolute;
  left: 8%;
  top: 29%;
  font-size: 50px;

}
.tipsBox{
  width: 100%;
  height: 100%;
  position: relative;
  // margin: -3px -12px;
  background: url('../assets/images/火星资源.png') no-repeat;
  background-size: cover;
}
.tips{
  max-width: 43%;
  font-size: 22px;
  line-height: 40px;
  position: absolute;
  left: 8%;
  top: 44%;
}
a{
 color: #409EFF;
 text-decoration: none;
}
.pageLogo{
  position: absolute;
  width: 150px;
  left: 50px;
  top: 50px;
}

</style>
